<template>
    <div class="px-8 md:px-32">
        <!-- beasiswa diploma -->
        <h1 class="font-bold mb-2 text-5xl lg:text-left py-5 md:mt-12">
            Beasiswa Diploma
        </h1>

        <!-- status & periode -->
        <div class="flex flex-wrap py-5">
            <!-- beasiswa diploma -->
            <div class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 flex-auto pr-5">
                <div class="bg-white">
                    <div class="">
                        <h2 class="font-bold mb-2 text-2xl text-left">
                            Status Pendaftaran
                        </h2>
                        <div class="flex pt-2">
                            <img
                                src="https://lh3.googleusercontent.com/6FU6CsorjphBgD_GaHiPHlCPBSrER9zgJj9nVZ0f-qfpshpMHzo0zUl8d5x-5kEQgBx354oHv-O5_R3WLQlgYaB-LZaS955s09HnSwRGlgUlGCjpmIKkiabjObb7gZoYkqR3yIJADA=s16-p-k"
                                alt=""
                                class="rounded-full h-5 w-5"
                            />
                            <p class="pl-2 mb-2">
                                {{ getStatus(diploma.status) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- periode pendaftaran -->
            <div class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 flex-auto pr-5">
                <div class="bg-white">
                    <div class="">
                        <h2 class="font-bold mb-2 text-2xl text-left">
                            Periode Pendaftaran
                        </h2>
                        <div class="flex pt-2">
                            <p class="mb-2">
                                {{ diploma.start }} - {{ diploma.end }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- periode pendaftaran -->
            <div class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 flex-auto pr-5">
                <div class="bg-white">
                    <div class="">
                        <h2 class="font-bold mb-2 text-2xl text-left">
                            Periode Beasiswa
                        </h2>
                        <div class="flex pt-2">
                            <p class="mb-2">2 Semester</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- button -->
        <div class="flex py-5">
            <a href="https://simawa.ipb.ac.id/" class="pr-5">
                <button
                    class="rounded-full px-5 py-2 bg-blue-400 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white"
                >
                    Daftar Beasiswa
                </button>
            </a>

            <a href="/" class="">
                <button
                    class="rounded-full border-2 border-blue-400 px-5 py-2 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-blue-400"
                >
                    Bagikan Beasiswa
                </button>
            </a>
        </div>

        <!-- deskripsi beasiswa -->
        <!-- <div class="py-5">
            <h2 class="font-bold mb-2 text-2xl text-left">
                Deskripsi Beasiswa
            </h2>
            <p class="text-left">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus
                totam recusandae explicabo necessitatibus excepturi esse
                reiciendis sequi aliquid harum, eum iste in corporis dignissimos
                officia nihil ullam impedit sapiente quae.
            </p>
        </div> -->

        <!-- cakupan beasiswa -->
        <div class="py-5">
            <h2 class="font-bold mb-2 text-2xl text-left">Cakupan Beasiswa</h2>
            <ul class="text-left list-disc list-inside">
                <li class="pb-2">Besaran beasiswa Rp 1.000.000/bulan.</li>
                <li class="pb-2">
                    Pelatihan penulisan, public speaking, & leadership camp.
                </li>
                <li class="pb-2">
                    Kegiatan social project & pengalaman networking.
                </li>
            </ul>
        </div>

        <!-- persyaratan -->
        <div class="py-5">
            <h2 class="font-bold mb-2 text-2xl text-left">Perysaratan</h2>
            <ul class="text-left list-disc list-inside">
                <li class="pb-2">
                    Mahasiswa aktif program Diploma D3 reguler (tidak dalam
                    status cuti akademik dan belum mempunyai SKL).
                </li>
                <li class="pb-2">Telah menyelesaikan minimal 40 SKS.</li>
                <li class="pb-2">
                    Memiliki Indeks Prestasi Kumulatif (IPK) minimal 3.00 dalam
                    skala 4.
                </li>
                <li class="pb-2">Mahasiswa semester 4 ke atas</li>
                <li class="pb-2">
                    Usia maksimal 22 tahun pada saat menerima beasiswa.
                </li>
                <li class="pb-2">
                    Tidak sedang menerima beasiswa atau berada dalam status
                    ikatan dinas dari lembaga/instansi lain, serta bersedia
                    tidak menerima beasiswa lain selama menerima beasiswa BI.
                </li>
                <!-- TODO: fix the nested list -->
                <li class="pb-2">
                    Diutamakan jurusan berkaitan dengan:
                    <ul class="text-left list-disc list-inside">
                        <li class="pb-2">Pertanian.</li>
                        <li class="pb-2">Peternakan.</li>
                        <li class="pb-2">Pariwisata</li>
                        <li class="pb-2">Kelautan</li>
                        <li class="pb-2">Perikanan</li>
                        <li class="pb-2">Ekonomi Kreatif</li>
                    </ul>
                </li>

                <li class="pb-2">
                    Memiliki minat dan pengalaman menjalankan aktivitas media
                    sosial yang mempunyai dampak kebermanfaatan bagi masyarakat.
                </li>
                <li class="pb-2">
                    Bersedia berperan aktif dalam Generasi Baru Indonesia
                    (GENBI) dan berpartisipasi dalam kegiatan yang
                    diselenggarakan Bank Indonesia.
                </li>
            </ul>
        </div>

        <!-- dokumen yang diperlukan -->
        <div class="py-5">
            <h2 class="font-bold mb-2 text-2xl text-left">
                Dokumen yang diperlukan
            </h2>
            <ul class="text-left list-disc list-inside">
                <li class="pb-2">Fotocopy KTM dan KTP.</li>
                <li class="pb-2">Scan Kartu kKluarga.</li>
                <li class="pb-2">Transkrip nilai semester terakhir.</li>
                <li class="pb-2">SKTM (opsional).</li>
                <li class="pb-2">Slip gaji orang tua.</li>
                <li class="pb-2">
                    Surat rekomendasi berasal dari satu tokoh akademik.
                </li>
                <li class="pb-2">Membuat personal resume 1 lembar.</li>
                <li class="pb-2">
                    Membuat motivation letter dalam Bahasa Indonesia 1 lembar.
                </li>
                <li class="pb-2">
                    Formulir lainnya yang dibutuhkan simawa.ipb.ac.id.
                </li>
            </ul>
        </div>

        <!-- dokumen kamu sudah siap? -->
        <div class="flex py-5">
            <div class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 flex-auto">
                <div class="bg-white rounded-3xl shadow-md p-3">
                    <div class="text-left flex flex-wrap p-2">
                        <div class="md:flex-grow">
                            <h2 class="font-bold mb-2 text-2xl">
                                Dokumen kamu sudah siap?
                            </h2>

                            <p class="mb-2">
                                Yuk daftarkan dirimu menjadi bagian GenBI IPB,
                                energi untuk negeri!
                            </p>
                        </div>

                        <div class="pt-5">
                            <a href="https://simawa.ipb.ac.id/" class="">
                                <button
                                    class="rounded-full px-5 py-2 bg-blue-400 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white"
                                >
                                    Daftar Beasiswa
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BreadCrumb from '../components/BreadCrumb'
import moment from 'moment'
import axios from 'axios'
export default {
    components: {
        // BreadCrumb,
    },
    mounted() {
        window.scrollTo(0, 0)
        this.getDate()
    },
    data() {
        return {
            diploma: {},
        }
    },

    methods: {
        getStatus(status) {
            switch (status) {
                case 'BUKA':
                    return 'Pendaftaran Dibuka'
                case 'TUTUP':
                    return 'Pendaftaran Ditutup'
                case 'MENUNGGU':
                    return 'Pendaftaran Belum Dibuka'
            }
        },
        async getDate() {
            const { data } = await axios.get(
                'https://api.backendless.com/1BFCE5AC-540E-AC5C-FF52-59629EC7E000/C3E94AD5-075F-4391-8E10-88B3FD4E31BF/services/websitegenbi/registration-dates'
            )
            console.log(data)
            data.forEach(date => {
                // console.log(date.type)
                switch (date.type) {
                    case 'diploma':
                        this.diploma = {
                            start: moment(date.start_date).format(
                                'DD MMMM YYYY'
                            ),
                            end: moment(date.end_date).format('DD MMMM YYYY'),
                            status: date.status,
                        }
                        break
                }
            })
            // this.dates = newDates
            // console.log(this.dates)
        },
    },
}
</script>

<style>
ul li::marker {
    color: lightgrey;
    /* font-size: 3rem;
    margin-right: 0rem !important; */
}
</style>
